import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57998989"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flow content-wrapper" }
const _hoisted_2 = { class: "controls" }
const _hoisted_3 = { class: "zoom" }
const _hoisted_4 = { class: "button" }
const _hoisted_5 = { class: "button" }
const _hoisted_6 = { class: "button" }
const _hoisted_7 = { class: "hierarchy" }
const _hoisted_8 = {
  key: 0,
  class: "material-icons-round"
}
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_flow_loader = _resolveComponent("flow-loader")!
  const _component_transition_fade = _resolveComponent("transition-fade")!
  const _component_background = _resolveComponent("background")!
  const _component_flow_node_entry = _resolveComponent("flow-node-entry")!
  const _component_flow_node_block = _resolveComponent("flow-node-block")!
  const _component_flow_node_flow = _resolveComponent("flow-node-flow")!
  const _component_flow_node_intent = _resolveComponent("flow-node-intent")!
  const _component_flow_node_condition = _resolveComponent("flow-node-condition")!
  const _component_flow_node_delay = _resolveComponent("flow-node-delay")!
  const _component_flow_node_split = _resolveComponent("flow-node-split")!
  const _component_flow_node_outlet = _resolveComponent("flow-node-outlet")!
  const _component_flow_node_code = _resolveComponent("flow-node-code")!
  const _component_flow_node_loader = _resolveComponent("flow-node-loader")!
  const _component_flow_node_note = _resolveComponent("flow-node-note")!
  const _component_vue_flow = _resolveComponent("vue-flow")!
  const _component_flow_panel = _resolveComponent("flow-panel")!
  const _component_flow_panel_transition = _resolveComponent("flow-panel-transition")!
  const _component_flow_menu = _resolveComponent("flow-menu")!
  const _component_transition_md_menu = _resolveComponent("transition-md-menu")!
  const _component_flow_steps_sidebar = _resolveComponent("flow-steps-sidebar")!
  const _component_md_fab = _resolveComponent("md-fab")!
  const _component_md_button = _resolveComponent("md-button")!
  const _component_md_tooltip = _resolveComponent("md-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_transition_fade, null, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_flow_loader, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_vue_flow, {
      modelValue: _ctx.nodes,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nodes) = $event)),
      "connection-mode": _ctx.ConnectionMode.Loose,
      "connection-line-style": _ctx.connectionLineStyle,
      "node-types": _ctx.nodeTypes,
      "edge-types": _ctx.edgeTypes,
      onConnect: _ctx.onConnect,
      onConnectStart: _ctx.connectStart,
      onConnectEnd: _ctx.connectEnd,
      onLoad: _ctx.onFlowLoad,
      onNodeClick: _ctx.onNodeClick,
      onPaneClick: _ctx.onPaneClick,
      onEdgeClick: _ctx.onEdgeClick,
      onMove: _ctx.onFlowMove,
      onNodeDragStop: _ctx.onNodeDragStop,
      onDragover: _ctx.onDragOver,
      onDrop: _ctx.onDrop,
      minZoom: 0.1,
      maxZoom: 2.5
    }, {
      "node-entry": _withCtx((props) => [
        _createVNode(_component_flow_node_entry, _normalizeProps(_guardReactiveProps(props)), null, 16)
      ]),
      "node-block": _withCtx((props) => [
        _createVNode(_component_flow_node_block, _normalizeProps(_guardReactiveProps(props)), null, 16)
      ]),
      "node-flow": _withCtx((props) => [
        _createVNode(_component_flow_node_flow, _normalizeProps(_guardReactiveProps(props)), null, 16)
      ]),
      "node-intent": _withCtx((props) => [
        _createVNode(_component_flow_node_intent, _normalizeProps(_guardReactiveProps(props)), null, 16)
      ]),
      "node-condition": _withCtx((props) => [
        _createVNode(_component_flow_node_condition, _normalizeProps(_guardReactiveProps(props)), null, 16)
      ]),
      "node-delay": _withCtx((props) => [
        _createVNode(_component_flow_node_delay, _normalizeProps(_guardReactiveProps(props)), null, 16)
      ]),
      "node-split": _withCtx((props) => [
        _createVNode(_component_flow_node_split, _normalizeProps(_guardReactiveProps(props)), null, 16)
      ]),
      "node-outlet": _withCtx((props) => [
        _createVNode(_component_flow_node_outlet, _normalizeProps(_guardReactiveProps(props)), null, 16)
      ]),
      "node-code": _withCtx((props) => [
        _createVNode(_component_flow_node_code, _normalizeProps(_guardReactiveProps(props)), null, 16)
      ]),
      "node-loader": _withCtx((props) => [
        _createVNode(_component_flow_node_loader, _normalizeProps(_guardReactiveProps(props)), null, 16)
      ]),
      "node-note": _withCtx((props) => [
        _createVNode(_component_flow_node_note, _normalizeProps(_guardReactiveProps(props)), null, 16)
      ]),
      default: _withCtx(() => [
        true
          ? (_openBlock(), _createBlock(_component_background, {
              key: 0,
              variant: "dots",
              gap: 20,
              size: 1,
              color: "#aaa"
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "connection-mode", "connection-line-style", "node-types", "edge-types", "onConnect", "onConnectStart", "onConnectEnd", "onLoad", "onNodeClick", "onPaneClick", "onEdgeClick", "onMove", "onNodeDragStop", "onDragover", "onDrop", "minZoom", "maxZoom"]),
    _createVNode(_component_flow_panel_transition, null, {
      default: _withCtx(() => [
        (_ctx.panelVisible)
          ? (_openBlock(), _createBlock(_component_flow_panel, {
              key: 0,
              node: _ctx.targetNode,
              "onUpdate:node": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.targetNode) = $event)),
              onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.panelVisible = false)),
              ref: "flowPanel",
              onDelete: _ctx.onTargetNodeDelete,
              large: _ctx.panelLarge
            }, null, 8, ["node", "onDelete", "large"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_transition_md_menu, null, {
      default: _withCtx(() => [
        (_ctx.menuVisible)
          ? (_openBlock(), _createBlock(_component_flow_menu, {
              key: 0,
              x: _ctx.menuX,
              y: _ctx.menuY,
              items: _ctx.menuItems,
              modelValue: _ctx.menuValue,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.menuValue) = $event)),
              onSelect: _ctx.onMenuItemClick
            }, null, 8, ["x", "y", "items", "modelValue", "onSelect"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_transition_md_menu, null, {
      default: _withCtx(() => [
        (_ctx.buttonConnectMenuVisible)
          ? (_openBlock(), _createBlock(_component_flow_menu, {
              key: 0,
              x: _ctx.menuX,
              y: _ctx.menuY,
              items: _ctx.buttonConnectMenuItems,
              modelValue: _ctx.menuValue,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.menuValue) = $event)),
              onSelect: _ctx.onButtonConnectItemClick,
              class: "block-connect-menu"
            }, null, 8, ["x", "y", "items", "modelValue", "onSelect"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.stepsSidebarVisible)
      ? (_openBlock(), _createBlock(_component_flow_steps_sidebar, {
          key: 0,
          onMinimize: _cache[5] || (_cache[5] = ($event: any) => (_ctx.stepsSidebarVisible = false)),
          subFlow: _ctx.parents.length > 1
        }, null, 8, ["subFlow"]))
      : (_openBlock(), _createBlock(_component_md_fab, {
          key: 1,
          class: "steps-sidebar-fab",
          icon: "add_circle",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.stepsSidebarVisible = true))
        })),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_md_tooltip, {
          position: "top",
          text: "Vergrößern",
          class: "zoom-in"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_md_button, {
              variant: "icon",
              icon: "add",
              grey: "",
              mode: "icon_small",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.flow.zoomIn()))
            })
          ]),
          _: 1
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.zoomLevel) + " %", 1),
        _createVNode(_component_md_tooltip, {
          position: "top",
          text: "Verkleinern",
          class: "zoom-out"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_md_button, {
              variant: "icon",
              icon: "remove",
              grey: "",
              size: "small",
              mode: "icon_small",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.flow.zoomOut()))
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_md_tooltip, {
          position: "top",
          text: "Alles anzeigen"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_md_button, {
              variant: "icon",
              icon: "fullscreen",
              grey: "",
              mode: "icon_small",
              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.flow.fitView()))
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_md_tooltip, {
          position: "top",
          text: "Schritte anordnen"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_md_button, {
              variant: "icon",
              icon: "merge",
              grey: "",
              mode: "icon_small",
              onClick: _ctx.onLayout
            }, null, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_md_tooltip, {
          position: "top",
          text: "Sperren"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_md_button, {
              variant: "icon",
              icon: "lock",
              grey: "",
              mode: "icon_small"
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parents, (parent, k) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: k }, [
          (k != 0)
            ? (_openBlock(), _createElementBlock("i", _hoisted_8, "keyboard_arrow_right"))
            : _createCommentVNode("", true),
          _createElementVNode("span", {
            onClick: ($event: any) => (_ctx.goToFlow(parent.id))
          }, _toDisplayString(parent.name), 9, _hoisted_9)
        ], 64))
      }), 128))
    ])
  ]))
}