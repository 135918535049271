
import { defineComponent } from "vue";
import MdTabs from "@/components/md/MdTabs/MdTabs.vue";

interface IDashboardItem {
  icon: string;
  title: string;
  description: string;
  path: string;
  disabled?: boolean;
}

export default defineComponent({
  name: "Dashboard",
  components: {
    MdTabs,
  },
  data() {
    return {
      items: [
        {
          icon: "timeline",
          title: "Versionen",
          description: "",
          path: "/versions",
          disabled: false,
        },
        {
          icon: "inbox",
          title: "Posteingang",
          description: "",
          path: "/inbox",
          disabled: true,
        },
        {
          icon: "merge_type",
          title: "Bot Builder",
          description: "",
          path: "/flows",
          disabled: false,
        },
        {
          icon: "travel_explore",
          title: "Entitäten",
          description: "",
          path: "/entities",
          disabled: true,
        },
        {
          icon: "perm_media",
          title: "Mediathek",
          description: "",
          path: "/files",
          disabled: true,
        },
        {
          icon: "list",
          title: "Logs",
          description: "",
          path: "/logs",
          disabled: true,
        },
        {
          icon: "psychology",
          title: "Verständnis",
          description: "",
          path: "/understanding",
          disabled: true,
        },
        {
          icon: "model_training",
          title: "Training",
          description: "",
          path: "/training",
          disabled: true,
        },
        {
          icon: "multiline_chart",
          title: "Analyse",
          description: "",
          path: "/analytics",
          disabled: true,
        },
        {
          icon: "question_answer",
          title: "FAQ",
          description: "",
          path: "/faqs",
          disabled: true,
        },
        {
          icon: "supervisor_account",
          title: "Zielgruppen",
          description: "",
          path: "/audiences",
          disabled: true,
        },
        {
          icon: "send",
          title: "Broadcasts",
          description: "",
          path: "/broadcasts",
          disabled: true,
        },
        {
          icon: "extension",
          title: "Kanäle",
          description: "",
          path: "/channels",
          disabled: true,
        },
        {
          icon: "wysiwyg",
          title: "WhatsApp Templates",
          description: "",
          path: "/templates",
          disabled: true,
        },
        {
          icon: "settings",
          title: "Einstellungen",
          description: "",
          path: "/settings",
          disabled: true,
        },
      ] as IDashboardItem[],
    };
  },
  computed: {
    disabledItems(): IDashboardItem[] {
      return this.items.filter((item) => item.disabled);
    },
    activeItems(): IDashboardItem[] {
      return this.items.filter((item) => !item.disabled);
    },
  },
  methods: {
    onItemClick(item: IDashboardItem) {
      if (item.disabled) {
        return;
      }
      this.$router.push("/bot/" + this.$route.params.chatbotId + item.path);
    },
  },
});
